import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import Script from "next/script";
import Head from "next/head";
import React from "react";
import "tailwindcss/tailwind.css";
import "../styles/globals.css";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <Component {...pageProps} />
    </>
  );
}
export default appWithTranslation(MyApp);
